import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Row, Col } from "reactstrap"
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const IndustryPartner = () => {
  const data = useStaticQuery(
    graphql`
      query {
        hofer: file(relativePath: { eq: "partner/alpha-retail.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, toFormat: PNG) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        bmw: file(relativePath: { eq: "partner/bmw-group.tif" }) {
          childImageSharp {
            fluid(maxWidth: 400, toFormat: PNG) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `)

  return (
    <Row className="text-center align-items-center justify-content-center">
      <Col xs="6" lg="4">
        <OutboundLink href="https://www.bmwgroup.com">
          <Img fluid={data.bmw.childImageSharp.fluid} className="mr-2 m-md-5" alt="BMW Group" />
        </OutboundLink>
      </Col>
      <Col xs="6" lg="4">
        <OutboundLink href="https://www.hofer.at">
          <Img fluid={data.hofer.childImageSharp.fluid} className="ml-2 m-md-5" alt="Alpha Retail Network - Hofer" />
        </OutboundLink>
      </Col>
    </Row>
  )
}

export default IndustryPartner