import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Button, Badge, ListGroupItem, ListGroup } from "reactstrap"
import IndustryPartner from "../components/industry-partner"
import ScientificPartner from "../components/scientific-partner"
import Newsletter from "../components/newsletter";
import { FaTree, FaShip, FaShippingFast, FaCheck } from "react-icons/fa"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className="embed-responsive embed-responsive-16by9">
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
)

export default ({data}) => (
  <Layout>
    <SEO title="Josef Ressel Center for Real-time Value Network Visibility" />

    <div className="text-white">
      <Video
        videoSrcURL="https://www.youtube-nocookie.com/embed/kGuIoacnsVI?controls=0&loop=1&version=3&autoplay=1&playlist=kGuIoacnsVI"
        videoTitle="JRC LIVE - Regaining supply chain sovereignty."
      />
      {/*<Container className="h-100">
        <Row className="h-100 align-items-center">
          <Col md="10" xl="6">
            <h1 className="display-1 text-white">Real-time Value Network Visibility.</h1>
            <p className="lead py-3">Regaining supply chain sovereignty.</p>
            <Button to="/contact/" color="light" size="lg" tag={Link}>Contact us for more information</Button>
          </Col>
        </Row>
      </Container>*/}
    </div>

    {/*<section>
      <Container className="my-5 py-5">
        <Row className="py-5 my-5">
          <Col md="4">
            <h2 className="display-4 text-primary">Our Mission.</h2>
            <p className="lead mt-4">Making your supply chain transparent.</p>
          </Col>
          <Col>
            <Row>
              <Col className="mb-5">
                <h3>1. Analyzation of existing data.</h3>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
              </Col>
              <Col className="mb-5" md={{ size: 5, offset: 1 }}>
                <h3>2. Importing the data in our system.</h3>
                <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3>3. Processing the imported data.</h3>
                <p>Reasoning, Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
              </Col>
              <Col md={{ size: 5, offset: 1 }}>
                <h3>4. Real-time monitoring of your supply chain.</h3>
                <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>*/}

    <section>
      <Container className="mt-xl-5 pt-3 pt-xl-5">
        <Row>
          <Col xl="4">
            <h2 className="display-4 text-primary">Your Benefits.</h2>
            <p className="lead my-4">
              JRC LIVE is an integrated and customizable solution, developed for 
              logisticians by logisticians.
            </p>
          </Col>
          <Col>
            <Row>
              <ListGroup flush className="w-100 mb-5">
                <ListGroupItem className="lead">
                  <FaCheck className="mr-2 text-muted" /> 
                  Confident decision making and value network control
                </ListGroupItem>
                <ListGroupItem className="lead">
                  <FaCheck className="mr-2 text-muted" />
                  Understand your network structure, critical partners and routes – to know where to look first
                </ListGroupItem>
                <ListGroupItem className="lead">
                  <FaCheck className="mr-2 text-muted" /> 
                  Understand performance in your network in order to differentiate systemic failure from disruption
                </ListGroupItem>
                <ListGroupItem className="lead">
                  <FaCheck className="mr-2 text-muted" /> 
                  Distinguish relevant information from noise
                </ListGroupItem>
              </ListGroup>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-5 mb-lg-0">
          <Col md={{size: 8}}>
            <Img fluid={data.logisticsImg.childImageSharp.fluid} className="shadow rounded-top" />
          </Col>
          <Col md={{size: 4}} className="mt-3 mt-md-0 text-md-right">
            <h2 className="display-6">Sounds promising?</h2>
            <p className="lead">
              Are you interested in gaining hands-on experience?
            </p>
            <Button color="primary" size="lg" tag={Link} to="/contact/">Contact us</Button>
          </Col>
        </Row>

        <Row className="my-2 my-md-5">
          <Col xl="4">
            <h2 className="display-4 text-primary mb-4">Your Return on Invest.</h2>
          </Col>
          <Col>
            <Row>
              <Col className="mb-2 mb-md-4">
                <h3>
                  <FaTree className="mr-2" />
                  Reduced CO<sub>2</sub> Footprint
                </h3>
                <p className="lead">
                  Gaining efficiency in your supply chain not only helps you save time and money 
                  but may also help you reducing your CO<sub>2</sub> footprint.
                </p>
              </Col>
              <Col md={{ size: 5, offset: 1 }} className="mb-2 mb-md-4">
                <h3>
                  <FaShip className="mr-2" />
                  Accurate Tracking
                </h3>
                <p className="lead">
                  Real-time monitoring of your ships, trains and trucks by enriching your internal 
                  data with external data sources.
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="my-2 my-md-4">
                <h3>
                  <FaShippingFast className="mr-2" />
                  Higher Efficiency
                </h3>
                <p className="lead">
                  Increased transparency leads to higher efficiency (shorter lead times and lower inventory levels).
                </p>
              </Col>
              <Col md={{ size: 5, offset: 1 }} className="my-2 my-md-4">
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>

    <section className="bg-light">
      <Container className="py-2 py-xl-5">
        <Row className="my-5 align-items-center">
          <Col xl="4">
            <h2 className="display-4">Our Approach.</h2>
            <p className="lead my-4">
              Start small, step by step. Think big.
            </p>
          </Col>
          <Col>
            <Row>
              <Col className="my-2 my-md-4">
                <h3><Badge className="mr-1" color="primary" pill>1</Badge> Supply Chain Analytics</h3>
                <p className="lead">
                  Understand static and dynamic criticality in your system.
                </p>
              </Col>
              <Col md={{ size: 5, offset: 1 }} className="my-2 my-md-4">
                <h3><Badge className="mr-1" color="primary" pill>2</Badge> Rule-Based Reasoning</h3>
                <p className="lead">
                  Derive rules in order to monitor static and dynamic criticality in your system. Fact and rule-based reasoning in real-time.
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="my-2 my-md-4">
                <h3><Badge className="mr-1" color="primary" pill>3</Badge> Customized &amp; Integrated Visuals</h3>
                <p className="lead">
                  Develop customized visuals in order to have full control on criticality for visual-based decision-making.
                </p>
              </Col>
              <Col md={{ size: 5, offset: 1 }} className="my-2 my-md-4">
                <h3><Badge className="mr-1" color="primary" pill>4</Badge> System Integration</h3>
                <p className="lead">
                  Purposefully enrich knowledge by integrating external data sources. Go Live.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>

    <section className="bg-light">
      <Container className="py-2 py-xl-5 border-top">
        <Row className="my-5 align-items-center">
          <Col xl="4">
            <h2 className="display-4">Functionality.</h2>
            <p className="lead my-4">
              See our Josef Ressel Center research lab in Eberstalzell in action.
            </p>
          </Col>
          <Col>
            <div class="embed-responsive embed-responsive-16by9 shadow">
              {/*<iframe src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6587699981908488192?compact=1" frameborder="0" allowfullscreen="" title="JRC LIVE Functionality" className="embed-responsive-item"></iframe>*/}
              <iframe title="JRC LIVE Functionality" src="https://www.youtube.com/embed/sg2-7tSaXLg?autoplay=1&version=3&loop=1&playlist=sg2-7tSaXLg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </section>

    {/*
    <div className="bg-light">
      <Container className="my-5 py-5">
        <Row className="py-5 my-5">
          <Col md="4">
            <h2 className="display-4 text-secondary">Timeline.</h2>
            <p className="lead mt-4">Making your supply chain transparent.</p>
          </Col>
          <Col>
            <Row>
              <Col className="mb-5">
                <h3 className="text-secondary">1. Analyzation of existing data.</h3>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
              </Col>
              <Col className="mb-5" md={{ size: 5, offset: 1 }}>
                <h3 className="text-secondary">2. Importing the data in our system.</h3>
                <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="text-secondary">3. Processing the imported data.</h3>
                <p>Reasoning, Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
              </Col>
              <Col md={{ size: 5, offset: 1 }}>
                <h3 className="text-secondary">4. Real-time monitoring of your supply chain.</h3>
                <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>*/}

    {/*
    <Container>
      <Steps />
    </Container>
    */}

    <section>
      <Container className="py-xl-5">
        <Row className="py-5 mt-5 align-items-center">
          <Col xl="4">
            <h2 className="display-4">Industry Partners.</h2>
            <p className="lead mt-4">
              Together with <OutboundLink href="https://www.bmwgroup.com">BMW Group</OutboundLink> and the{' '}
              <OutboundLink href="https://www.hofer.at/de/unternehmen/presse/presseaussendungen/unternehmen/alpha-retail-network/">Alpha Retail Network</OutboundLink>{' '}
              by <OutboundLink href="https://www.hofer.at">Hofer</OutboundLink> we generate expertise in both automotive and retail logistics.
            </p>
          </Col>

          <Col>
            <IndustryPartner />
          </Col>
        </Row>
      </Container>

      <Container className="py-xl-5 border-top">
        <Row className="py-5 mb-5 align-items-center">
          <Col xl={{/*order: 2, */size: 4}}>
            <h2 className="display-4">Scientific Partners.</h2>
            <p className="lead mt-4">
              We are working closely with our scientific partners, including the <OutboundLink href="https://www.uni-mannheim.de">University of Mannheim</OutboundLink>,{' '}
              <OutboundLink href="https://www.unibw.de">Bundeswehr University Munich</OutboundLink>,{' '}
              <OutboundLink href="https://www.ox.ac.uk">University of Oxford</OutboundLink>,{' '}
              and the <OutboundLink href="https://www.logistikum.at">Logistikum</OutboundLink> of the{' '}
              <OutboundLink href="https://www.fh-ooe.at">University of Applied Sciences Upper Austria</OutboundLink>.
            </p>
          </Col>

          <Col>
            <ScientificPartner />
          </Col>
        </Row>
      </Container>
    </section>

    <Newsletter />
  </Layout>
)

export const query = graphql`
  query {
    logisticsImg: file(relativePath: { eq: "logistics-singapore.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`