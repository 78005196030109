import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Row, Col } from "reactstrap"
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const ScientificPartner = () => {
  const data = useStaticQuery(
    graphql`
      query {
        oxford: file(relativePath: { eq: "partner/oxford-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 400, toFormat: PNG) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        mannheim: file(relativePath: { eq: "partner/uni-mannheim.png" }) {
          childImageSharp {
            fluid(maxWidth: 400, toFormat: PNG) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        unibw: file(relativePath: { eq: "partner/uni-bw.png" }) {
          childImageSharp {
            fluid(maxWidth: 400, toFormat: PNG) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        fhooe: file(relativePath: { eq: "partner/fh-ooe.png" }) {
          childImageSharp {
            fluid(maxWidth: 400, toFormat: PNG) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        logistikum: file(relativePath: { eq: "partner/logistikum.png" }) {
          childImageSharp {
            fluid(maxWidth: 400, toFormat: PNG) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        cdg: file(relativePath: { eq: "partner/cdg.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, toFormat: PNG) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `)

  return (
    <Row className="text-center align-items-center justify-content-center project-partners">
      <Col xs="6" lg="4">
        <OutboundLink href="https://www.uni-mannheim.de">
          <Img fluid={data.mannheim.childImageSharp.fluid} className="m-3 m-md-5" alt="University of Mannheim" />
        </OutboundLink>
      </Col>
      <Col xs="6" lg="4">
        <OutboundLink href="https://www.unibw.de">
          <Img fluid={data.unibw.childImageSharp.fluid} className="m-3 m-md-5" alt="Bundeswehr University München" />
        </OutboundLink>
      </Col>
      <Col xs="6" lg="4">
        <OutboundLink href="https://www.ox.ac.uk">
          <Img fluid={data.oxford.childImageSharp.fluid} className="m-3 m-md-5" alt="University of Oxford" />
        </OutboundLink>
      </Col>
      <Col xs="6" lg="4">
        <OutboundLink href="https://www.logistikum.at">
          <Img fluid={data.logistikum.childImageSharp.fluid} className="m-3 m-md-5" alt="Logistikum" />
        </OutboundLink>
      </Col>
      <Col xs="6" lg="4">
        <OutboundLink href="https://www.fh-ooe.at/campus-steyr/">
          <Img fluid={data.fhooe.childImageSharp.fluid} className="m-3 m-md-5" alt="FH OÖ" />
        </OutboundLink>
      </Col>
      <Col xs="6" lg="4">
        <OutboundLink href="https://www.cdg.ac.at/forschungseinheiten/labor/echtzeitvisualisierung-von-wertschoepfungsnetzwerken/?tx_cdglabors_labors%5Baction%5D=show&tx_cdglabors_labors%5Bcontroller%5D=Labor&cHash=faf9d36d049114c78ff692d1b91111e8">
          <Img fluid={data.cdg.childImageSharp.fluid} className="m-3 m-md-5" alt="Christian Doppler Forschungsgesellschaft" />
        </OutboundLink>
      </Col>
    </Row>
  )
}

export default ScientificPartner